import './fixed-banner.module.scss';
import React, { Component } from 'react';
import axios from 'axios';
import { validateForm } from '../../assets/js/custom-validation';
import logo from '../../assets/images/logo.png';

const { apiUrl } = process.env;
const { mailTo } = process.env;
const { mailCc } = process.env;
const { marketingWebUrl } = process.env;
/* eslint-disable-next-line */
export interface FixedBannerProps {
  heading?: string;
  formComponent?: boolean;
  content?: string;
  bannerImage?: string;
  containerSm?: boolean;
}

class FixedBanner extends Component<FixedBannerProps> {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      areYou: '',
      state: '',
      city: '',
      zip: '',
      message: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }

  handleContactFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #118440; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear DGM Team,<br><br>You are receiving this because someone has posted a New Contact Inquiry from the website. <a href="${marketingWebUrl}">${marketingWebUrl}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Phone</b></td><td><span class="vcolor">[Phone]</span></td></tr>
                          <tr><td><b>Email*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                          <tr><td><b>Are You A</b></td><td><span class="vcolor">[AreYou]</span></td></tr>
                          <tr><td><b>State</b></td><td><span class="vcolor">[State]</span></td></tr>
                          <tr><td><b>City</b></td><td><span class="vcolor">[City]</span></td></tr>
                          <tr><td><b>Zip/Postal Code</b></td><td><span class="vcolor">[Zip]</span></td></tr>
                          <tr><td colspan='2'><b>How can we help you?*</b></td></tr>
                          <tr><td colspan='2'><span class="vcolor">[Message]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, DGM Team<br><br><img src="${logo}" width="10%" alt="DGM"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[Phone]', this.state.phone)
        .replace('[Email]', this.state.email)
        .replace('[AreYou]', this.state.areYou)
        .replace('[State]', this.state.state)
        .replace('[City]', this.state.city)
        .replace('[Zip]', this.state.zip)
        .replace('[Message]', this.state.message);

      const data = {
        emailTo: mailTo,
        emailCc: mailCc,
        emailSubject: 'New Contact Inquiry - DGM Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${apiUrl}/mailer/send`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                areYou: '',
                state: '',
                city: '',
                zip: '',
                message: '',
                mailSent: true,
                isValidated: false,
              });
              //window.location.reload();
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }
    this.setState({ isValidated: true });
    return false;
  }

  render() {
    const { heading, formComponent, content, bannerImage, containerSm } =
      this.props;

    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }
    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <section id="fixed-banner-content">
        <div className="banner-image-container">
          {bannerImage && (
            <div className="banner-image">
              <img src={bannerImage} alt="who we are" />
            </div>
          )}
          <div className="banner-title">{heading}</div>
        </div>
        <div className="fixed-content">
          <div
            className={
              containerSm === true
                ? `content-container form-content`
                : `content-container`
            }
          >
            {content}
            {formComponent === true && (
              <div className="form-banner">
                <form
                  method="post"
                  action="#"
                  ref={(form) => {
                    this.formEl = form;
                  }}
                  className={classNames}
                  noValidate
                >
                  {this.state.mailSent && (
                    <div className="alert-success alert text-center mb-5">
                      Thank you for contacting us.
                    </div>
                  )}
                  {this.state.error && (
                    <div className="alert-danger error alert text-center  mb-5">
                      {this.state.error}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="First Name*"
                        required
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="Last Name*"
                        required
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="Phone"
                        value={this.state.phone}
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="Email"
                        required
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                      <div className="invalid-feedback" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="Are You A"
                        value={this.state.areYou}
                        onChange={(e) => {
                          this.setState({ areYou: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="State"
                        value={this.state.state}
                        onChange={(e) => {
                          this.setState({ state: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="City"
                        value={this.state.city}
                        onChange={(e) => {
                          this.setState({ city: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="phone"
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="Zip / Postal Code"
                        value={this.state.zip}
                        onChange={(e) => {
                          this.setState({ zip: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        name=""
                        className="form-control form-dark mar-bottom-rem form-bg-white"
                        placeholder="How can we help you?"
                        value={this.state.message}
                        onChange={(e) => {
                          this.setState({ message: e.target.value });
                        }}
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn-theme btn-md"
                        onClick={(e) => this.handleContactFormSubmit(e)}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default FixedBanner;
