import './index.module.scss';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Newsletter from '../../components/newsletter/newsletter';
import FixedBanner from '../../components/fixed-banner/fixed-banner';
import bannerAbout from '../../assets/images/who-we-are.jpg';
import vasIcon from '../../assets/images/vas.svg';
import dollarIcon from '../../assets/images/dollar-coin.svg';
import groupIcon from '../../assets/images/group.svg';
import shareIcon from '../../assets/images/share-money.svg';
import TreeCol from '../../components/tree-col/tree-col';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <FixedBanner
        heading={<h2 className="large-heading">Who We Are</h2>}
        bannerImage={bannerAbout}
        content={
          <div>
            <h3 className="medium-heading">Our Story</h3>
            <p className="norma-size-font">
              <strong className="reg-size-font">
                DGM was formed in 1999 by Wall Street professionals with the
                express interest of bringing institutional products, research,
                advice and money management to individuals who were not
                institutional investors by nature. At the time, most of Wall
                Street was based upon a commission structure that focused on
                selling products to clients. Feeling there was a better way, DGM
                became an early advocate of the RIA structure, which makes us
                fiduciaries to the clients. As a Registered Investment Advisor,
                we are independent and thus have been able to offer clients
                advice free of any of the conflicts of interest that have been
                shown to hamper many of Wall Street's bigger brokerages. Our
                monthly newsletter has been available for over twenty years, and
                there are no impediments to us “telling it like it is.”
                Everything we do is fee-based on assets under management so as
                our clients’ assets grow, our fee does as well. There are no
                other commissions or remuneration for the firm for anything
                else. We are truly on the same side of the table as you, and our
                paths are aligned; grow the assets with an appropriate plan
                designed specifically for each individual investor.
                <br />
                <br />
                Listening to our clients’ needs is the cornerstone of everything
                we do at DGM. There is no cookie-cutter model; our approach to
                wealth management enables clients to pursue their professional
                and personal goals, and to prepare for the future while
                preserving their purchasing power over time. This begins with a
                risk-assessment of each client to understand and quantify their
                objectives and the risk, or lack thereof, that individual is
                willing to assume to reach those goals. Understanding each
                person’s risk threshold, goals and concerns provides a
                foundation for clear communication on the road ahead. The
                Founders’ intent was always to deliver the best, most
                straightforward advice, while developing deep personal
                relationships that support long-standing friendships.
                <br />
                <br />
                Our Wealth Management Team develops a plan, based on this
                quantified risk assessment, that our clients can understand and
                adopt as their own.We actively draw upon the expertise of our
                firm’s different disciplines and partners to provide the
                planning needed to ensure solutions that will span generations.
                We believe in the power of using ETFs to help develop a
                portfolio that will be tax-friendly, diversified, and low-fee.
                DGM and their trusted partners offer not only investment advice,
                but also advice involving taxation, estate planning, and other
                areas of expertise to be certain that short-term solutions do
                not eclipse long-term objectives and strategies of our clients.
                Life will throw obstacles at you and one needs to be able to not
                just have a plan, but get back on course when the winds blow you
                in a different direction. That’s where we come in-we will help
                in real time no matter what the markets-or life-brings you.
                Consider us your guide down the path of life. Helping others
                achieve their goals is our goal, and we walk together with you
                each step of the way.
              </strong>
            </p>
          </div>
        }
      />

      <div className="container">
        <div className="serv-tree">
          <h3 className="medium-heading font-secondary text-center mar-bottom-rem">
            Our Values
          </h3>
          <p className="text-center">
            Our mission is to be a wealth advisory firm that truly understands
            what matters most to our clients and act a thoughtful steward of
            their complete financial picture. We aim to provide an emotionally
            and financially rewarding work environment that balances a desire
            for excellence and personal growth with the importance of family and
            community.
          </p>
        </div>
        <div className="serv-tree">
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">
                The client comes first.
              </h2>
            }
            iconImage={vasIcon}
            content={
              <p>
                Everything we do centers around the fact that the client always
                is first and foremost. By being an independent RIA, we avoid all
                conflicts of interest and do everything in the client’s best
                interests. We look at a client’s entire picture and design a
                bespoke plan for that individual only.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">
                We love what we do.
              </h2>
            }
            iconImage={dollarIcon}
            content={
              <p>
                As the old expression goes, “When you love your work you never
                really work.” From the intellectual challenges of managing money
                and adapting to the ever changing financial cycles we
                collectively face with the markets as they change to helping
                clients achieve their goals, we feel like we have the best job
                in the world. We are on call 24/7/365 not because we have to but
                because we want to.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">
                Information is Power.
              </h2>
            }
            iconImage={shareIcon}
            content={
              <p>
                We believe that the more information we can provide our clients,
                the better the outcomes will be for everyone involved. We have
                written our in-house newsletter for twenty years and with that
                in mind, and are always sending market updates at times where
                investors may have questions or concerns. We pay for research
                and information from some of Wall Street’s best sources so we
                can pass that along to you as well.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">
                Independence is Freedom.
              </h2>
            }
            iconImage={groupIcon}
            content={
              <p>
                By being a Registered Investment Advisory, we have a fiduciary
                obligation to always put the client first. We have all the
                benefits of partnering with a major custodian while having none
                of the negatives of having to answer to a major investment firm.
                We only answer to our clients, one individual at a time.
              </p>
            }
          />
          <TreeCol
            title={
              <h2 className="medium-heading color-theme">Communication.</h2>
            }
            iconImage={groupIcon}
            content={
              <p>
                We know that the markets, like life, can throw up obstacles to
                one’s plans for the future. Thus, we believe in communicating as
                much as possible through as many sources as possible to our
                clients as to what is happening, what our plans are, and any
                changes that may be needed. Whether it is a newsletter, a text,
                a phone call or a social media post, we believe that in this
                case, more is better when it comes to keeping our clients
                informed.
              </p>
            }
          />
        </div>
      </div>
      <Newsletter />
      <Footer />
    </Layout>
  );
}

export default Index;
